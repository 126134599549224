<template>
   <div class="error">
      <h1 class="error__title">{{ $t('404Page.errorTitle') }}</h1>
      <p class="error__text">{{ $t('404Page.errorMessage') }}</p>
      <router-link class="error__link" :to="{ name: 'home' }">
         <SecontBtn :additionalStyles="'margin: 0 auto;'">{{ $t('404Page.btnText') }}</SecontBtn>
      </router-link>
   </div>
</template>

<script>
import SecontBtn from '../components/UI/SecondBtn.vue';
export default {
   components: {
      SecontBtn,
   },
};
</script>

<style lang="sass" scoped>
.error
	text-align: center

	&__title
		font-size: 2.5rem
		margin-bottom: 1rem

	&__text
		margin-bottom: 1rem
		font-size: 2rem

	&__link
		text-decoration: none
</style>